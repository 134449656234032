<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1 v-if="this_category && this_category.name">Объявления - {{this_category.name}}</h1>
                <h1 v-else>Объявления - {{ $myCity.name }}</h1>
            </div>
            <div class="default_box_content" v-if="preLoad">
                <b-row no-gutters>
                    <b-col cols="6" sm="4" md="3" v-for="(item, index) in list" :key="index" class="cat_new">
                        <router-link :to="{name:(item.category_children_count>0)?'AdsCategory':'AdsList' ,params: { id:item.id }}">
                            <div class="cat_item">
                                <img :src="item.icon" alt="icon" class="icon" v-if="item.icon" />
                                <div v-html="item.name" class="cat_item_name"></div>
                            </div>
                        </router-link>
                    </b-col>
                </b-row>
            <AdsPlaginNew :direction="'horizontal'" class="main_plagin" />
            </div>
            <div v-else>
                <PreLoadBox></PreLoadBox>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AdsCategory",
    components: {},
    metaInfo() {
        return {
            title: this.this_category && this.this_category.name ? `Объявления ${this.this_category.name} ` : (this.list && this.list.length ? `Объявления ` : null),
            meta: [
                {name: 'description', itemprop: 'description', content: (this.this_category && this.this_category.name) ? `Объявления ${this.this_category.name} . В городе ${this.$myCity.name}.` : `Объявления в городе ${this.$myCity.name}.` },
                { property: 'og:url', content: this.$config.url + this.$route.fullPath },
                { property: 'og:title', content: (this.this_category && this.this_category.name) ? `Объявления ${this.this_category.name} - ${this.$myCity.name}` : `Объявления - ${this.$myCity.name}` },
                { property: 'og:description', content: (this.this_category && this.this_category.name) ? `Объявления ${this.this_category.name} . В городе ${this.$myCity.name}.` : `Объявления в городе ${this.$myCity.name}.` },
                { property: 'og:image', content: 'https://okaygorod.com/images/logoHD.png' },
                { property: 'og:type', content: 'website' }
            ]
        }
    },
    data() {
        return {
            preLoad: null,
            list: [],
            this_category: null
        }
    },
    methods: {
        api() {
            this.preLoad = null;
            this.$scrollToTop();
            let url = this.$config.api_url + this.$route.params.city + '/Ads.getList';
            this.$http.get(url, {
                params: {
                    id: this.$route.params.id
                }
            }).then((response) => {
                this.list = response.data.response.category_list;
                this.this_category = response.data.response.this_category;
            }).finally(() => {
                this.preLoad = 'finish';
            })
        },
    },
    mounted() {
        this.api();
    },
    watch: {
        '$route.params.id': function() {
            this.api();
        }
    }
}
</script>
<style scoped>
@media (min-width: 768px) {
    .adscat_cat:hover {
        background: rgba(74, 118, 168, .05);
        transition: .2s;
        cursor: pointer;
    }

}

.adscat_item {
    width: 100%;
    padding: 10px 15px;
}

.adscat_cat {
    text-align: left;
    border-radius: 12px;
}

.cat_new {
    padding: 15px !important;
    flex-wrap: wrap;
}

.cat_new a {
    text-decoration: none;
    color: #2c3e50;
    font-size: 14px;
}

.cat_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    height: 100%;
    transition: .2s;
}



.cat_item img {
    margin: 0 0 20px;
}

@media (min-width: 992px) {
    .cat_item:hover {
        transform: translateY(-5px);
        transition: .2s;
    }
}



.adscat_cat a {
    text-decoration: none;
    color: #2c3e50;
}

.adscat_cat img {
    padding: 5px;
    margin-right: 10px;
    margin-left: 10px;
    color: #4a76a8;
    border-radius: 5px;
}

.icon {
    max-width: 32px;
    max-height: 32px;
}
</style>
